






























import Vue from "vue";
import { SearchStandardisedProfiles } from "@/interfaces/recruiter/jobs/post_job/search_standardised_profiles";
import AutoCompleteJobSearch from "@/components/shared/jobs/AutoCompleteJobSearch.vue";
import router from "@/router";
import { mapMutations } from "vuex";
import { VIEW_JOB_STATE } from "@/store/modules/common/constants";
import { ViewJobState } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "SearchStandardisedProfiles",
  components: { AutoCompleteJobSearch },
  data(): SearchStandardisedProfiles {
    return {
      form: false,
      search: "",
      search_rules: [
        (value: string) => !!value || this.$t("recruiter.field-required-rule")
      ]
    };
  },
  methods: {
    ...mapMutations("common", {
      set_view_job_state: VIEW_JOB_STATE
    }),
    async submit_form() {
      if (this.search) {
        this.set_view_job_state(ViewJobState.STANDARDISED_JOB_POST);
        await router.push(`/recruiter/jobs/post-job/search/${this.search}`);
      }
    },
    async search_job(title: string) {
      this.search = title;
      if (this.search) {
        // this.set_view_job_state(ViewJobState.STANDARDISED_JOB_POST);
        await router.push(`/recruiter/jobs/post-job/search/${this.search}`);
      }
    }
  }
});




































































































import Vue from "vue";
import { UploadJobFile } from "@/interfaces/recruiter/jobs/post_job/upload_job_file";
import { get_file_size, validate_file_type } from "@/utils/global";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  RECRUITER_LOADING,
  POST_JOB,
  RECOMMEND_JOB_PROFILES
} from "@/store/modules/recruiter/constants";
import {
  UploadedFile,
  ValidateFileType
} from "@/interfaces/shared/file_validation";
import router from "@/router";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { VIEW_JOB_STATE } from "@/store/modules/common/constants";
import { ViewJobState } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "UploadJobFile",
  data(): UploadJobFile {
    return {
      job_files: [],
      selected_files: [],
      drop_file_image: require("@/assets/illustrations/file.svg"),
      error: false,
      error_message: "",
      file_id: 1,
      is_valid: false
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      post_job_loading: RECRUITER_LOADING
    }),
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("recruiter", {
      post_job: POST_JOB
    }),
    ...mapMutations("common", {
      set_view_job_state: VIEW_JOB_STATE
    }),
    ...mapMutations("recruiter", {
      set_recommend_job_profiles: RECOMMEND_JOB_PROFILES
    }),
    get_file_size,
    /**
     * Process user selected files, through drag & drop
     * @param value{DragEvent}
     */
    process_dropped_files(value: DragEvent) {
      if (value && value.dataTransfer) {
        const files: File[] = Array.from<File>(value.dataTransfer.files);
        this.validate_file(files);
      }
    },
    /**
     * Process user selected files, through file uploader
     * @param files {File} => Selected file
     */
    process_selected_files(files: File[]) {
      this.validate_file(files);
    },

    /**
     * Validate selected file type & size: .docs, .pdf & .txt are valid types
     * @param files{File} => selected file
     * @return boolean
     */
    validate_file(files: File[]) {
      for (let file of files) {
        const valid_file = this.validate_individual_file(file);
        const obj: UploadedFile = {
          file,
          error: !valid_file.valid,
          error_message: valid_file.msg,
          id: this.file_id
        };
        this.selected_files.push(obj);
        this.file_id += 1;
      }
      this.valid_files();
    },
    valid_files() {
      this.is_valid = true;
      for (let file of this.selected_files) {
        if (file.error) {
          this.is_valid = false;
          break;
        }
      }
    },
    /**
     * Validate selected file type: .docs, .pdf & .txt are valid types
     * @param file {File} => selected file
     * @return boolean
     */
    validate_individual_file(file: File): ValidateFileType {
      if (file.size > 3e7) {
        return {
          valid: false,
          msg: this.$t("recruiter.file-size-rule").toString()
        };
      } else if (
        validate_file_type("text", file) ||
        validate_file_type("pdf", file) ||
        validate_file_type("word", file)
      ) {
        return {
          valid: true,
          msg: ""
        };
      } else {
        return {
          valid: false,
          msg: this.$t("recruiter.allowed-files-rule").toString()
        };
      }
    },
    remove_job_file(file: UploadedFile) {
      this.selected_files = this.selected_files.filter(
        (_file) => _file.id !== file.id
      );
      this.valid_files();
    },
    /**
     * Submit form
     */
    async submit_form() {
      const obj = new FormData();
      this.selected_files.map((a) => {
        obj.append("jobs", a.file);
        return a.file;
      });
      obj.append("post_type", "file");
      const response = await this.post_job(obj);
      if (response) {
        if (this.selected_files.length > 1) {
          await router.push("/recruiter/jobs");
        } else {
          this.set_recommend_job_profiles(response.recommend_job_profiles);
          this.set_view_job_state(ViewJobState.FILE_JOB_POST);
          await router.push(`/job/${response.standardized_data.jid}`);
        }
        this.root_notification(this.$t("success-messages.job-post-success"));
      } else {
        this.root_error(this.$t("errors.job-post-failed"));
      }
    }
  }
});

































































import Vue from "vue";
import { JobPostingOption } from "@/interfaces/recruiter/jobs/post_job/post_job";
import SearchStandardisedProfiles from "@/components/recruiter/jobs/post_job/SearchStandardisedProfiles.vue";
import LinkedinJobLink from "@/components/recruiter/jobs/post_job/LinkedinJobLink.vue";
import AddJobDetails from "@/components/recruiter/jobs/post_job/AddJobDetails.vue";
import UploadJobFile from "@/components/recruiter/jobs/post_job/UploadJobFile.vue";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { mapGetters } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "PostNewJob",
  components: {
    GoBackHeader,
    UploadJobFile,
    AddJobDetails,
    LinkedinJobLink,
    SearchStandardisedProfiles
  },
  data() {
    return {
      back_icon: require("@/assets/icons/linear/arrow-left-1.svg"),
      posting_options: [] as JobPostingOption[]
    };
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  watch: {
    get_site_direction() {
      this.set_options();
    }
  },
  created() {
    this.set_options();
  },
  methods: {
    set_options() {
      this.posting_options = [
        {
          icon: require("@/assets/logos/linkedin4.svg"),
          title: this.$t("recruiter.post-job.link-card-title"),
          key: 1,
          active: false
        },
        {
          icon: require("@/assets/illustrations/files2.svg"),
          title: this.$t("recruiter.post-job.file-card-title"),
          key: 2,
          active: false
        },
        {
          icon: require("@/assets/illustrations/pages.svg"),
          title: this.$t("recruiter.post-job.text-card-title"),
          key: 3,
          active: false
        }
      ];
    },
    activate_job_option(value: number) {
      const index = value - 1;
      this.posting_options.forEach((option, _index) => {
        if (_index === index) {
          option.active = !option.active;
          // Scroll to options section
          const job_posting_options = this.$refs
            .job_posting_options as HTMLElement;
          job_posting_options.scrollIntoView({ behavior: "smooth" });
        } else option.active = false;
      });
    }
  }
});

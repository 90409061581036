
































import Vue from "vue";
import { LinkedinJobLink } from "@/interfaces/recruiter/jobs/post_job/linkedin_job_link";
import { get_linkedin_regex } from "@/utils/global";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  POST_JOB,
  RECOMMEND_JOB_PROFILES,
  RECRUITER_LOADING
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import router from "@/router";
import { ViewJobState } from "@/store/modules/common/interfaces";
import { VIEW_JOB_STATE } from "@/store/modules/common/constants";
export default Vue.extend({
  name: "LinkedinJobLink",
  data(): LinkedinJobLink {
    return {
      form: false,
      job_link: "",
      link_icon: require("@/assets/icons/linear/link.svg"),
      rules: [
        (value: string) => !!value || this.$t("recruiter.field-required-rule"),
        (value: string) => {
          if (value) {
            if (get_linkedin_regex().test(value)) return true;
            else return this.$t("recruiter.invalid-link-rule");
          } else return true;
        }
      ]
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      post_job_loading: RECRUITER_LOADING
    })
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("recruiter", {
      post_job: POST_JOB
    }),
    ...mapMutations("recruiter", {
      set_recommend_job_profiles: RECOMMEND_JOB_PROFILES
    }),
    ...mapMutations("common", {
      set_view_job_state: VIEW_JOB_STATE
    }),
    async submit_form() {
      if (this.job_link) {
        const obj: FormData = new FormData();
        obj.append("post_type", "url");
        obj.append("url", this.job_link);
        obj.append("method", "linkedin");
        const job_posted = await this.post_job(obj);
        // const job_posted = job_with_linkedin_data;
        // If job not posted successfully
        if (!job_posted) {
          this.root_error(this.$t("errors.job-post-failed"));
        }
        // If job posted successfully
        else {
          this.root_notification(
            this.$t("success-messages.job-crawled-success")
          );
          // Set recommend job profiles
          this.set_recommend_job_profiles(job_posted.recommend_job_profiles);
          const job_id = job_posted.standardized_data.jid;
          // Set view job state
          this.set_view_job_state(ViewJobState.LINKEDIN_JOB_POST);
          // Navigate to jobs page
          await router.push(`/job/${job_id}`);
        }
      }
    }
  }
});
